var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.isHomePage ? 'header' : 'header not-home'},[_c('div',{class:_vm.isHomePage ? 'header-container' : 'header-container not-home',attrs:{"id":"header-container"}},[_c('div',{staticClass:"header-wrapper",class:{ wide: _vm.isWidePage }},[_c('div',{class:_vm.isCognitoUserLoggedIn
            ? 'header-logo-container logged-in'
            : 'header-logo-container'},[(_vm.isHomePage)?_c('a',{attrs:{"href":"/"}},[_c('img',{staticClass:"header-logo",attrs:{"src":"/site_images/logo_honely_2.png","alt":"Honely"}})]):_vm._e(),(!_vm.isHomePage)?_c('a',{attrs:{"href":"/"}},[_c('img',{staticClass:"header-logo",attrs:{"src":"/site_images/logo_honely.png","alt":"Honely"}})]):_vm._e()]),_c('div',{class:_vm.isCognitoUserLoggedIn
            ? 'header-menu-container-logged-in'
            : 'header-menu-container'},[_c('ul',{staticClass:"header-menu",class:_vm.isHomePage ? 'header-menu' : 'header-menu not-home',attrs:{"id":"header-menu"}},[_c('li',{attrs:{"id":"btn-mobile-menu-close"}},[_c('span',{staticClass:"mdi mdi-close",on:{"click":_vm.toggleMobileMenu}})]),_vm._l((_vm.headerMenu),function(item,i){return _c('li',[(item.text !== 'Realtors')?_c('router-link',{key:i,class:{ active: _vm.getActiveMenuLink == item.label },attrs:{"id":'header-menu-' + i,"to":item.link}},[_vm._v(" "+_vm._s(item.text)+" ")]):_vm._e(),(item.text === 'Realtors')?_c('a',{attrs:{"href":"https://realtors.honely.com"}},[_vm._v("Realtors")]):_vm._e()],1)})],2)]),_c('div',{class:_vm.isCognitoUserLoggedIn
            ? 'header-action-menu-container-logged-in'
            : 'header-action-menu-container'},[(_vm.isCognitoUserLoggedIn && _vm.userProfile)?_c('div',{class:_vm.isHomePage
              ? 'header-welcome-message-container'
              : 'header-welcome-message-container not-home'},[_c('span',[_vm._v("Welcome, "+_vm._s(_vm.userProfile.first_name)+" "+_vm._s(_vm.userProfile.last_name))])]):_vm._e(),(
            !(
              _vm.$route.name === 'Buying Landing Page' ||
              _vm.$route.name === 'Selling Landing Page' ||
              _vm.$route.name === 'Refinancing Landing Page' ||
              _vm.$route.name === 'Investing Landing Page'
            )
          )?_c('div',[(!_vm.isCognitoUserLoggedIn)?_c('button',{attrs:{"id":"btn_login"},on:{"click":function($event){return _vm.showLoginPopup()}}},[_vm._v(" Sign in ")]):_vm._e()]):_vm._e(),_c('div',[(_vm.isCognitoUserLoggedIn)?_c('span',{staticClass:"mdi mdi-account-circle",attrs:{"id":"btn_user_menu"},on:{"click":_vm.toggleUserMenu}}):_vm._e()]),(_vm.isCognitoUserLoggedIn)?_c('div',{staticClass:"menu-user",attrs:{"id":"menu-user"}},[_c('ul',{staticClass:"list-menu-user"},[_vm._l((_vm.actionMenuItems),function(item,i){return _c('li',[_c('router-link',{key:i,class:{ active: _vm.getActiveMenuLink == item.label },attrs:{"id":'header-action-menu-' + i,"to":item.link}},[_vm._v(" "+_vm._s(item.text)+" ")])],1)}),_vm._l((_vm.serviceProviderMenuItems),function(item,i){return (_vm.isServiceProvider)?_c('li',[_c('router-link',{key:i,class:{ active: _vm.getActiveMenuLink == item.label },attrs:{"id":'header-action-menu-' + (_vm.getActionMenuItemCount + i),"to":item.link}},[_vm._v(" "+_vm._s(item.text)+" ")])],1):_vm._e()}),_c('li',[_c('router-link',{attrs:{"id":'header-action-menu-99',"to":_vm.logoutLink.link}},[_vm._v(" "+_vm._s(_vm.logoutLink.text)+" ")])],1)],2)]):_vm._e(),_c('span',{staticClass:"mdi mdi-menu",attrs:{"id":"btn-mobile-menu"},on:{"click":_vm.toggleMobileMenu}})])])]),_c('div',{staticClass:"header-placeholder"}),_c('login-popup',{attrs:{"show":_vm.showLogin},on:{"toggleShow":_vm.toggleLoginPopupShow}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }